import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavbarComponent } from '@core/components/navbar/navbar.component';
import { SidebarComponent } from '@core/components/sidebar/components/sidebar.component';

@Component({
  standalone: true,
  selector: 'app-layout-admin',
  imports: [CommonModule, NavbarComponent, SidebarComponent],
  template: `
    <app-navbar />
    <app-sidebar class="h-full">
      <ng-content></ng-content>
    </app-sidebar>
  `,
})
export class LayoutAdminComponent {}
