import { Injectable, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { toObservable } from '@angular/core/rxjs-interop';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface Breadcrumb {
  title: string;
  url: string;
  icon?: IconProp;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbs = signal<Breadcrumb[]>([]);
  breadcrumbs$ = toObservable(this.breadcrumbs);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const root = this.activatedRoute.root;
        const breadcrumbs = this.getBreadcrumbs(root);

        this.breadcrumbs.set(breadcrumbs);
      });
  }
  private getBreadcrumbs(
    route: ActivatedRoute,
    url = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb'; // Use 'breadcrumb' key in your data object

    // Get the child routes
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet !== 'primary') {
        continue;
      }

      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join('/');
      url += `/${routeURL}`;

      let breadcrumbTitle = '';
      if (
        child.snapshot.params['id'] &&
        !breadcrumbs.find(
          breadcrumb => breadcrumb.title === child.snapshot.params['id']
        )
      ) {
        breadcrumbTitle = child.snapshot.params['id']; // attach id to breadcrumb if exists
      } else if (
        Object.hasOwnProperty.call(
          child.snapshot.data,
          ROUTE_DATA_BREADCRUMB
        ) &&
        child.snapshot.data[ROUTE_DATA_BREADCRUMB] !== null
      ) {
        breadcrumbTitle = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
      } else {
        continue;
      }

      const icon: IconProp = child.snapshot.data['icon'];
      const breadcrumb = {
        url: url,
        title: breadcrumbTitle,
        icon: icon,
      };

      breadcrumbs.push(breadcrumb);
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}
