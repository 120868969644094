import { Injectable, signal } from '@angular/core';
import { Theme } from '@core/enums/theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  theme = signal<Theme>(Theme.LIGHT);

  constructor() {
    this.theme.set((localStorage.getItem('theme') as Theme) || Theme.LIGHT);
    this.updateBodyClass();
  }

  toggleTheme(): void {
    this.setTheme(this.theme() === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
  }

  setTheme(theme: Theme) {
    this.theme.set(theme);
    localStorage.setItem('theme', this.theme());
    this.updateBodyClass();
  }

  getTheme() {
    return this.theme();
  }

  private updateBodyClass(): void {
    document.body.classList.remove(Theme.LIGHT, Theme.DARK);
    document.body.classList.add(this.theme());
  }
}
