import { Component, computed, input, OnInit, signal } from '@angular/core';
import { FeaturePreview } from '@core/feature-preview/types/feature-preview';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { isFeatureEnabled } from '@core/feature-preview/is-feature-enabled';
import { disableFeature } from '@core/feature-preview/disable-feature';
import { enableFeature } from '@core/feature-preview/enable-feature';

@Component({
  standalone: true,
  selector: 'app-feature-preview-list-item',
  imports: [MatSlideToggle, ReactiveFormsModule],
  template: `
    <div class="mb-6 flex flex-col gap-2">
      <div class="flex w-full justify-between">
        <h3 class="text--contrast-lightest text-xl font-bold">
          {{ feature().name }}
        </h3>
        <mat-slide-toggle
          color="primary"
          [checked]="isEnabled()"
          (toggleChange)="onToggleChange()"></mat-slide-toggle>
      </div>
      <p>{{ feature().description }}</p>
    </div>
  `,
})
export class FeaturePreviewListItemComponent implements OnInit {
  /**
   * INPUTS
   */
  feature = input.required<FeaturePreview>();

  key = computed(() => this.feature().key);
  isEnabled = signal(false);

  ngOnInit() {
    this.isEnabled.set(isFeatureEnabled(this.key()));
  }

  onToggleChange() {
    if (this.isEnabled()) {
      this.isEnabled.set(false);
      disableFeature(this.key());
    } else {
      this.isEnabled.set(true);
      enableFeature(this.key());
    }
  }
}
