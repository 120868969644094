import { Component, computed } from '@angular/core';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import {
  FaIconComponent,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { toSignal } from '@angular/core/rxjs-interop';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  standalone: true,
  selector: 'app-sidebar-toggle',
  imports: [FaIconComponent, MatTooltip],
  template: `
    <button
      (click)="onClicked()"
      [attr.aria-label]="label()"
      [matTooltip]="label()"
      matTooltipShowDelay="300">
      <fa-icon
        class="text--hover-highlight transform--rotate text-xl"
        [icon]="['fas', 'arrow-right-from-bracket']"
        [class.rotate-180]="!collapsed()"></fa-icon>
    </button>
  `,
})
export class SidebarToggleComponent {
  /**
   * PROPERTIES
   */
  private readonly shortcutLabel = 'Ctrl + b';
  private ariaLabel = computed(() => (this.collapsed() ? 'Open' : 'Collapse'));
  label = computed(() => `${this.ariaLabel()} ${this.shortcutLabel}`);
  collapsed = toSignal(this.sidebarService.sidebarCollapsed$);

  constructor(
    library: FaIconLibrary,
    private sidebarService: SidebarService
  ) {
    library.addIcons(faArrowRightFromBracket);
  }

  onClicked(): void {
    if (this.collapsed()) {
      this.onOpenClicked();
    } else {
      this.onCollapseClicked();
    }
  }

  onOpenClicked(): void {
    this.sidebarService.open();
  }

  onCollapseClicked(): void {
    this.sidebarService.collapse();
  }
}
