import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { initSentry } from './sentry';
import { $localize } from '@angular/localize/init'; // <-- Import the $localize function from the Angular Localize package

const title = $localize`:@@app.title:Flowzilla`; // <-- Use the $localize to prevent removing unused imports 😉
console.log('Application initialized:', title);

initSentry();
bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
