import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { selectAuthUser } from '@core/states/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@core/states/app.state';
import { NavbarComponent } from '@core/components/navbar/nav-menu/navbar-menu-item.component';
import * as AuthActions from '@core/states/auth/auth.actions';
import { RouterLink } from '@angular/router';
import { HasRoleDirective } from '@core/directives/has-role.directive';
import { Role } from '@core/states/auth/enums/role';
import { MatDialog } from '@angular/material/dialog';
import { FeaturePreviewDialogComponent } from '@core/feature-preview/dialog/feature-preview-dialog.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'app-nav-menu-content',
  imports: [NavbarComponent, RouterLink, HasRoleDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      @apply absolute -left-20 top-8 z-50 mt-2 flex w-screen max-w-min -translate-x-1/2 px-2;
    }
  `,
  template: `
    <div
      class="text-md flex w-56 shrink flex-col gap-2 rounded-lg bg-white p-4 font-normal shadow-xl ring-1 ring-neutral-900/5 dark:bg-black dark:ring-neutral-500/50"
      (click)="itemClicked.emit()">
      <!-- User -->
      @if (user()) {
        <a
          class="group flex cursor-pointer gap-2 rounded-lg p-2 hover:bg-neutral-50 hover:dark:bg-neutral-900"
          [routerLink]="['profile']">
          <span
            class="inline-block size-8 cursor-pointer overflow-hidden rounded-full bg-neutral-100">
            <svg
              class="h-full w-full text-neutral-400 dark:text-neutral-500 "
              fill="currentColor"
              viewBox="0 0 24 24">
              <path
                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
          <span class="self-center"
            >{{ user()?.firstName }} {{ user()?.lastName }}</span
          >
        </a>

        <ng-container *appHasRole="[Role.ROLE_SUPER_ADMIN]">
          <app-navbar-menu-item
            [label]="'Preview Center'"
            [icon]="'lock'"
            (clickEvent)="onFeaturePreviewCenter()"></app-navbar-menu-item>
          <div class="w-full border border-b border-neutral-100/50"></div>
        </ng-container>

        <app-navbar-menu-item
          [label]="'Logout'"
          (clickEvent)="onLogout()"></app-navbar-menu-item>
      }
    </div>
  `,
})
export class NavMenuContentComponent {
  /**
   * OUTPUTS
   */
  itemClicked = output<void>();

  /**
   * INTERNALS
   */
  protected readonly Role = Role;
  user = this.store.selectSignal(selectAuthUser);

  constructor(
    private readonly store: Store<AppState>,
    private dialog: MatDialog,
    library: FaIconLibrary
  ) {
    library.addIcons(faLock);
  }

  onLogout() {
    this.store.dispatch(AuthActions.logout());
  }

  onFeaturePreviewCenter(): void {
    this.dialog
      .open(FeaturePreviewDialogComponent, {
        width: '40rem',
        disableClose: true,
      })
      .afterClosed()
      .subscribe(() => location.reload()); // Page refresh to update views
  }

  /** Click inside the open menu should be ignored because otherwise
   * the menu will be closed on clicking a button inside the menu
   * If that's the expected behavior delete this function here
   */
  /*@HostListener('click', ['$event'])
  onClickInside(event: Event) {
    event.stopPropagation()
  }*/
}
