import { Directive, HostBinding, input, OnInit } from '@angular/core';

@Directive({
  selector: '[appRandomSize]',
  standalone: true,
})
export class RandomSizeDirective implements OnInit {
  minSize = input(2);
  maxSize = input(4);

  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  size!: number;

  @HostBinding('style.left.%')
  left!: number;

  @HostBinding('style.top.%')
  top!: number;

  ngOnInit() {
    this.size =
      Math.floor(Math.random() * (this.maxSize() - this.minSize() + 1)) +
      this.minSize();
    this.left = Math.floor(Math.random() * 101);
    this.top = Math.floor(Math.random() * 101);
  }
}
