import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-gorilla-svg',
  imports: [CommonModule],
  template: `
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      [attr.width]="size"
      [attr.height]="size"
      [ngClass]="{ group: addGroup }"
      viewBox="0 0 278.18 281">
      <g
        class="fill-primary-500 stroke-primary-500"
        stroke="currentColor"
        fill="currentColor"
        stroke-width="3">
        <path
          d="M255.02,27.84L205.31,0l-36.05,35.54l-14.9,42.02l-54.3,48.73l-60.17,16.13l-11.14,26.05l-1.16,38.58
		c-1.69,3.47-9.75,20.35-13.4,27.93c-0.96-0.04-1.92,0.11-2.86,0.43c-1.96,0.68-3.53,2.09-4.44,3.96
		c-1.85,3.85-0.23,8.51,3.63,10.36c1.06,0.52,2.22,0.78,3.37,0.78c0.86,0,1.73-0.15,2.57-0.44c1.96-0.68,3.53-2.1,4.43-3.96
		c0.91-1.87,1.02-3.98,0.34-5.93c-0.32-0.93-0.81-1.78-1.44-2.5c4.19-8.61,14.11-29.08,14.11-29.08l1.16-38.74l9.46-22.12
		l58.67-15.72L159.8,81.2l14.95-42.17l31.61-31.17l43.94,24.6l12.69,23.75l-10.82,4.82l-13.17-6.46l-9.68,6.65l0,6.94l10.07-6.34
		l12.66,6.22l19.76-8.81L255.02,27.84z M16.49,244.01c-0.33,0.69-0.92,1.22-1.65,1.47c-0.73,0.25-1.51,0.2-2.21-0.13
		c-1.43-0.69-2.04-2.42-1.35-3.85c0.33-0.69,0.92-1.22,1.65-1.47c0.31-0.11,0.63-0.17,0.96-0.17c0.42,0,0.85,0.1,1.25,0.29
		c0.69,0.33,1.22,0.92,1.47,1.65c0.11,0.3,0.17,0.62,0.17,0.95C16.79,243.18,16.69,243.61,16.49,244.01z" />
        <polygon
          points="183.97,280.93 147.52,280.91 148.4,261.81 158.37,256.93 149.03,218.46 155.01,216.17 165.73,260.38
		154.57,265.85 154.17,274.68 179.33,274.68 193.26,230.98 197.82,237.56 	" />
        <polygon
          points="73.75,281 63.61,266.96 73.14,238.42 93.84,214.14 100.77,215.8 78.77,241.6 70.66,265.88 76.99,274.65
		99.47,274.61 99.59,269.14 91.18,260.87 112.61,247.15 125.47,221.92 131.92,223.47 117.47,251.57 101.29,261.93 105.99,266.54
		105.68,280.94 	" />
        <polygon
          points="12.83,281 0,263.87 4.79,253.98 10.5,256.75 7.39,263.16 16,274.65 40.57,274.61 40.28,264.6 33.53,257.46
		89.42,199.89 133.53,210.76 163.03,199.49 165.29,205.42 133.94,217.39 91.43,206.92 42.32,257.52 46.55,262 47.1,280.94 	" />
        <path
          d="M252.95,251.81l-1.27,22.73l-29.89-0.02l-7.07-11.85l11.39-6.37l-27.36-39.56l-6.86-38.99l-30.7-23.14l-0.88-11.44
		l11.57-23.55c0.12,0.01,0.22,0.01,0.34,0.01c2.87,0,5.64-1.6,6.99-4.34c1.89-3.84,0.3-8.51-3.54-10.4
		c-1.86-0.91-3.97-1.04-5.93-0.38c-1.96,0.67-3.55,2.07-4.47,3.93c-1.38,2.8-0.9,6.04,0.96,8.32l-12.37,25.17l1.24,16.02
		l30.98,23.35l6.67,37.9l24.19,34.96l-11,6.15l12.26,20.53l39.38,0.05l1.66-28.87L252.95,251.81z M169.65,110.59
		c0.34-0.69,0.93-1.21,1.66-1.45c0.3-0.11,0.62-0.16,0.94-0.16c0.43,0,0.87,0.1,1.27,0.29c1.43,0.7,2.02,2.44,1.32,3.86
		c-0.7,1.43-2.44,2.02-3.86,1.32C169.53,113.75,168.95,112.03,169.65,110.59z" />
        <path
          d="M272.62,112.15l5.56-29.77l-9.05-14.69l-11.32,5.09v6.51l8.84-3.54l4.84,7.87l-4.26,22.83l-28.61,3.69l-7.46-13.49
		l-13.48-6.27l-2.22,5.79l11.13,5.35l6.98,12.64l-2.4,39.49l24.52,43.5l-1.67,33.3c-2.71,1.11-4.67,3.73-4.81,6.83
		c-0.1,2.07,0.62,4.06,2.02,5.59c1.41,1.53,3.31,2.43,5.39,2.52c0.12,0.01,0.24,0.01,0.36,0.01c2.06,0,3.95-0.81,5.35-2.15
		c1.4-1.33,2.31-3.18,2.4-5.26c0.15-3.2-1.67-6.03-4.37-7.35l1.75-35.04l-24.48-43.43l2.18-35.77L272.62,112.15z M257.13,234.75
		c1.59,0.07,2.82,1.42,2.75,3.02c-0.08,1.59-1.43,2.82-3.02,2.75c-0.77-0.04-1.48-0.37-2-0.94c-0.53-0.58-0.79-1.31-0.75-2.08
		c0.07-1.55,1.35-2.76,2.88-2.76C257.04,234.74,257.08,234.74,257.13,234.75z" />
      </g>
    </svg>
  `,
})
export class GorillaSvgComponent {
  @Input() size = 40;
  @Input() addGroup = false;
}
