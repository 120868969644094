<div class="relative size-full overflow-clip flex flex-col flex-grow">
  <app-particles />
  <section class="bg--primary flow flex flex-col size-full items-center justify-center gap-y-12 overflow-clip">
    <div class="absolute inset-0 z-10 animate-pulse-slow bg-radial-primary"></div>
    <img class="max-w-72 w-full" [ngSrc]="thumbnail()" width="126" height="150" alt="flowzilla gorilla" priority />

    <div class="z-30 w-96">
      <ng-content />
    </div>
  </section>
</div>